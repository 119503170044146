import React from 'react';
import PropTypes from 'prop-types';
import { getAngularService } from 'NgMigrationUtils/angular-react-helper';
import { isPreview } from '@src/helpers/previewHelper';
import { stripHtml } from '@src/app/utils';
import { useDispatch } from 'react-redux';
import trackPiwikEvent from '@src/reduxStore/analytics/analyticsThunks';

const QuickSubNavigationItems = ({ subTree, active, closeMenu, settings }) => {
  const dispatch = useDispatch();
  const navigationService = getAngularService('navigationService');

  const setThemeEntry = (cluster, item) => {
    navigationService.setLatestClusterChildren(cluster.children);
    window.dispatchEvent(new CustomEvent('reloadBreadcrumbs'));
    window.dispatchEvent(new CustomEvent('changeThemeColor', { detail: item.color }));

    closeMenu();
  };

  const isPublished = (item) => item.issued || settings.allowNotPublished || isPreview();

  const isActiveCluster = (itemKey) => {
    if (!active) {
      return false;
    }

    if (active.key === itemKey) {
      return true;
    }

    const activeThemes = subTree.children.filter((theme) =>
      theme.children.some((item) => active && active.key === item.key)
    );

    return activeThemes.some((theme) => theme.key === itemKey);
  };

  return (
    <>
      <div className="cluster-menu">
        <div className="card-columns card-columns--2">
          {subTree &&
            subTree.children.map((cluster, index) => (
              <div className="card" key={index}>
                <ul className="desktop-menu__cluster">
                  <li
                    className={`desktop-menu__cluster-header ${
                      isActiveCluster(cluster.key) ? 'desktop-menu__cluster-header--active' : ''
                    }`}
                    style={{ color: subTree.color }}
                  >
                    <a
                      href={cluster.path || cluster.href}
                      onClick={closeMenu}
                      aria-label="themagroep"
                      dangerouslySetInnerHTML={{ __html: stripHtml(cluster.title) }}
                    />
                  </li>
                  {cluster.children
                    .filter((subItem) => subItem.visible && isPublished(subItem))
                    .filter((subItem) => subItem.importance === 'VERY_HIGH')
                    .map((subItem, subItemIndex) => (
                      <li
                        className="desktop-menu__cluster-item desktop-menu__cluster-item--important"
                        key={subItemIndex}
                      >
                        <button
                          onClick={() => setThemeEntry(cluster, subItem)}
                          className="kov-pro-btn kov-pro-btn--block kov-pro-btn__icon--right"
                          style={{
                            'background-color': subTree.color,
                            'border-color': subTree.color,
                          }}
                        >
                          <span dangerouslySetInnerHTML={{ __html: stripHtml(subItem.title) }} />
                          <i className="icon-kov-pro-arrow-right" />
                        </button>
                      </li>
                    ))}
                  {cluster.children
                    .filter((subItem) => subItem.visible && isPublished(subItem))
                    .filter((subItem) => subItem.importance !== 'VERY_HIGH')
                    .map((subItem, subItemIndex) => (
                      <li
                        className={`desktop-menu__cluster-item ${
                          active && active.key === subItem.key
                            ? 'desktop-menu__cluster-item--active'
                            : ''
                        } ${subItem.isPathExternal ? 'desktop-menu__cluster-item--external' : ''}`}
                        key={subItemIndex}
                        style={{ color: subTree.color }}
                      >
                        <a
                          href={subItem.path || subItem.href}
                          aria-label="thema"
                          target={subItem.isPathExternal ? '_blank' : undefined}
                          onClick={() => {
                            setThemeEntry(cluster, subItem);
                            if (
                              subItem.path.includes('/#!/nieuwsbrieven') ||
                              subItem.href.includes('/#!/nieuwsbrieven')
                            ) {
                              dispatch(trackPiwikEvent('start_newsletter'));
                            }
                          }}
                          rel="noreferrer"
                        >
                          <span dangerouslySetInnerHTML={{ __html: stripHtml(subItem.title) }} />
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

QuickSubNavigationItems.propTypes = {
  subTree: PropTypes.object,
  active: PropTypes.object,
  closeMenu: PropTypes.func,
  settings: PropTypes.object,
};

export default QuickSubNavigationItems;
