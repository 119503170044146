import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useUrlSearchParams from '@src/hooks/useUrlSearchParams';
import { getCurrentProTheme } from '@src/reduxStore/theme/themeSelectors';
import { themeActions } from '@src/reduxStore/theme/themeSlice';
import Banner from '@src/reactComponents/sections/banner/banner';
import {
  transformUIFiltersToParams,
  transformViewOptionsToParams,
  TYPE_FILTER_MAP,
  useSearchApi,
} from '@components/searchApi';
import transformSearchResult from '@components/globalSearch/globalSearchResults/globalSearchResultViewModel';
import { selectUserViewOptions } from '@src/reduxStore/user/userSelectors';
import trackPiwikEvent from '@src/reduxStore/analytics/analyticsThunks';
import GlobalSearchFilters from './globalSearchFilters/globalSearchFilters';
import MobileSearchBar from './mobileSearchBar/mobileSearchBar';
import GlobalSearchResults from './globalSearchResults/globalSearchResults';

const MIN_DESKTOP_WIDTH = 992;

const GlobalSearch = () => {
  const isInitialisation = useRef(true);
  const [isDesktop, setDesktop] = useState(window.innerWidth >= MIN_DESKTOP_WIDTH);
  const [filtersSidePanelOpened, setFiltersSidePanelOpened] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [bannerTitle, setBannerTitle] = useState('');
  const [typesFilter, setTypesFilter] = useState([]);
  const [scopeFilter, setScopeFilter] = useState(null);
  const currentTheme = useSelector(getCurrentProTheme);
  const viewOptions = useSelector(selectUserViewOptions);
  const dispatch = useDispatch();
  const previousSearchTerm = useRef();

  const { searchResults, error, isLoading, searchCount, getSearchResults, loadMoreSearchResults } =
    useSearchApi();

  const { updateUrlSearchParams, DEPRECATEDisStateChangedByUrl } = useUrlSearchParams(
    [
      { key: 'q', state: searchTerm, updateState: setSearchTerm },
      { key: 'types', state: typesFilter, updateState: setTypesFilter },
      {
        key: 'scope',
        state: scopeFilter,
        updateState: (themeHref) => {
          setScopeFilter(themeHref);
          if (themeHref && (!currentTheme || themeHref !== currentTheme.href)) {
            dispatch(themeActions.setCurrentTheme(themeHref));
          }
        },
      },
    ],
    true
  );

  const doUpdateSearchResults = () => {
    if (!viewOptions) return;

    const transformTypes = () => {
      let types = typesFilter;
      // if none of the filters are selected => that is the same as all the filters are selected
      if (types.length === 0) types = Object.keys(TYPE_FILTER_MAP);
      // if you search within a theme => do not search on job offers
      if (scopeFilter) types = types.filter((t) => t !== 'JOB_OFFERS');
      return types;
    };

    getSearchResults(
      {
        q: searchTerm,
        useCase: 'PRO_SEARCH',
        expand: 'summary',
        limit: 30,
        ...transformViewOptionsToParams(null, viewOptions),
        ...transformUIFiltersToParams({
          types: transformTypes(),
          searchOnlyWithinTheme: scopeFilter,
        }),
      },
      (r) => transformSearchResult(r, searchTerm)
    );

    setBannerTitle(searchTerm);
    setFiltersSidePanelOpened(false);
  };

  const updateSearchResultsRef = useRef(doUpdateSearchResults);
  useEffect(() => {
    updateSearchResultsRef.current = doUpdateSearchResults;
  });

  useEffect(() => {
    console.log('[global search component] global search component is intialized');
    const updateMedia = () => setDesktop(window.innerWidth >= MIN_DESKTOP_WIDTH);
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  useEffect(() => {
    if (searchTerm.length > 0 && previousSearchTerm.current !== searchTerm) {
      previousSearchTerm.current = searchTerm;
      dispatch(
        trackPiwikEvent('view_search_results', {
          search_term: searchTerm?.toLowerCase(),
          search_result_count: searchCount,
        })
      );
    }
  }, [searchResults]);

  useEffect(() => {
    if (isInitialisation.current || DEPRECATEDisStateChangedByUrl) {
      if (isInitialisation.current) {
        isInitialisation.current = false;
        console.log('[global search component] do initial search');
      } else {
        console.log('[global search component] search triggerd on state changed by URL');
      }
      updateSearchResultsRef.current();
    }
  }, [DEPRECATEDisStateChangedByUrl]);

  const doSearch = useCallback(() => {
    updateSearchResultsRef.current();
    setBannerTitle(searchTerm);
    updateUrlSearchParams();
  }, [updateUrlSearchParams]);

  const clearAllFilters = useCallback(() => {
    setTypesFilter([]);
    setScopeFilter(null);
  }, []);

  return (
    <>
      <Banner
        menuItem={{
          websiteType: 'search',
          title:
            bannerTitle && bannerTitle.length > 0
              ? `Zoekresultaten voor: ${bannerTitle}`
              : 'Zoeken',
        }}
      />
      <section
        id="search-results-page"
        style={{ marginTop: 'unset' }} // id="search-results-page" has margin 77px which we don't want... this id can not be removed
      >
        <div className="container">
          <div className="row">
            {(isDesktop || filtersSidePanelOpened) && (
              <GlobalSearchFilters
                searchTerm={searchTerm}
                typesFilter={typesFilter}
                scopeFilter={scopeFilter}
                theme={currentTheme}
                onUpdateSearchTerm={setSearchTerm}
                onUpdateTypesFilter={setTypesFilter}
                onUpdateScopeFilter={setScopeFilter}
                dispatchSearch={doSearch}
                closeFiltersSidePanel={() => setFiltersSidePanelOpened(false)}
                clearAllFilters={clearAllFilters}
              />
            )}
            <div className="col-12 col-md-12 col-lg-9">
              <MobileSearchBar
                searchTerm={searchTerm}
                typesFilter={typesFilter}
                scopeFilter={scopeFilter}
                onUpdateSearchTerm={setSearchTerm}
                dispatchSearch={doSearch}
                openFiltersSidePanel={() => setFiltersSidePanelOpened(true)}
              />
              <GlobalSearchResults
                results={searchResults}
                error={error}
                isLoading={isLoading}
                searchCount={searchCount}
                typesFilter={typesFilter}
                scopeFilter={scopeFilter}
                currentTheme={currentTheme}
                loadMoreResults={loadMoreSearchResults}
                searchTerm={searchTerm}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default GlobalSearch;
