import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Banner from '@components/sections/banner/banner';
import { useDispatch, useSelector } from 'react-redux';
import databaseSelectors from '@src/reduxStore/database/databaseSelectors';
import useDatabase from '@components/database/hooks/useDatabase';
import { cloneDeep } from 'lodash-es';
import { databaseActions } from '@src/reduxStore/database/databaseSlice';
import { DatabaseFilters, DatabaseResults, ThemeMenu } from '@components/database/components';
import { stripHtml } from '@src/app/utils';
import { OrderMethods } from '@components/database/constants';
import { useMediaQuery } from '@react-hook/media-query';
import SidebarPortal from '@components/sections/sidebar/SidebarPortal';
import SearchInput from '@UI/searchInput/searchInput';
import useZillCurricula from '@hooks/useZillCurricula';
import { getThemeTabs } from '@src/components/theme/templates/helpers/helpers';

const Database = ({ theme, database }) => {
  const isTablet = useMediaQuery('screen and (max-width: 992px)');
  const [filtersSidebarOpen, setFiltersSidebarOpen] = useState(false);
  const dispatch = useDispatch();
  const filters = useSelector(databaseSelectors.getFilters);
  const tempFilters = useSelector(databaseSelectors.getTempFilters);
  const zillCurricula = useZillCurricula(database.facets);
  const orderTypes = useMemo(
    () => [
      ...(database.facets.find((facet) => facet.component === 'FULL_TEXT_SEARCH')
        ? [OrderMethods.RELEVANCE]
        : []),
      OrderMethods.NEWEST,
      OrderMethods.OLDEST,
    ],
    [database]
  );

  // it could happen that the "proTheme" is not a MINI_DATABASE and still we got here when
  // we are trying to render a MINI_DATABASE included in another type of PRO_THEME (like THEME_HOME) (eg.:/duaal-leren-inspiratie)
  const isStandalonePage = theme ? theme.pageType === 'MINI_DATABASE' : true;
  const pageTabs = getThemeTabs(theme, isStandalonePage);
  const showTabs = pageTabs.length > 0;
  const showPageTitle = database.href !== theme?.href;
  const pageTitle = database.title;

  const {
    updateUrlSearchParams,
    numFiltersApplied,
    searchCount,
    loadMoreSearchResults,
    isLoading,
    searchResults,
  } = useDatabase(database);

  const hideFilters = useMemo(() => {
    if (!database) return true;

    return (
      !filters.ageRangeFrom &&
      !filters.ageRangeTo &&
      !database.facets.find((facet) => facet.component === 'FULL_TEXT_SEARCH') &&
      !filters.zillGoals &&
      filters.requirements.length === 0
    );
  }, [filters, database]);

  const updateSelectedReferences = useCallback(
    (key) => {
      const newReferences = cloneDeep(tempFilters.requirements);

      newReferences.forEach((ref) => {
        ref.values.forEach((val) => {
          if (val.key === key) {
            val.checked = !val.checked;
          }
        });
      });
      dispatch(databaseActions.setFilter({ fieldName: 'requirements', payload: newReferences }));
    },
    [dispatch, tempFilters.requirements]
  );

  const onUpdateSubCurricula = (subCurriculaHrefs) => {
    dispatch(
      databaseActions.setFilter({
        fieldName: 'curricula',
        payload: subCurriculaHrefs,
        applyFieldImmediately: true,
      })
    );
  };

  return database ? (
    <>
      <Banner menuItem={database} theme={theme} onUpdateSubCurricula={onUpdateSubCurricula} />
      <section id="database-page" style={{ padding: `${!showTabs ? '25px' : '0'} 0 50px 0` }}>
        {showTabs && <ThemeMenu theme={theme} items={pageTabs} current={database?.href} />}
        <div className="container">
          <div className="row">
            {showPageTitle && (
              <div className="kov-pro-theme-main-content-title col-12">
                <h1>{stripHtml(pageTitle)}</h1>
              </div>
            )}
            {!isTablet && !hideFilters && (
              <div className="kov-sidebar__wrapper" id="kov-sidenav-filter">
                <DatabaseFilters
                  database={database}
                  theme={theme}
                  filters={tempFilters}
                  zillCurricula={zillCurricula}
                  hideFilters={hideFilters}
                  dispatch={dispatch}
                  update={updateSelectedReferences}
                  onClearFilters={updateUrlSearchParams}
                  doSearch={() => {
                    dispatch(databaseActions.applyTempFilter());
                  }}
                />
              </div>
            )}
            {isTablet &&
              database.facets.find((facet) => facet.component === 'FULL_TEXT_SEARCH') && (
                <div className="kov-pro-filter col-12" style={{ paddingBottom: 0 }}>
                  <div className="kov-pro-mini-search input-group" style={{ margin: 0 }}>
                    <SearchInput
                      placeholder="Zoek"
                      value={tempFilters.q}
                      onSearch={() => dispatch(databaseActions.applyTempFilter())}
                      onClear={() => {
                        dispatch(databaseActions.setFilter({ fieldName: 'q', payload: '' }));
                        dispatch(databaseActions.applyTempFilter());
                      }}
                      onChange={(term) =>
                        dispatch(databaseActions.setFilter({ fieldName: 'q', payload: term }))
                      }
                      inputClassName="form-control"
                      showSearchIconAfter
                    />
                  </div>
                </div>
              )}
            <div className={'kov-pro-content col-12 col-md-12 col-lg-9'}>
              {isTablet && (
                <div className="kov-pro-filter--mobile d-flex">
                  <button
                    onClick={() => setFiltersSidebarOpen((prev) => !prev)}
                    className="kov-pro-btn kov-pro-btn--dark kov-pro-btn__icon--left kov-pro-btn--inverted kov-pro-btn--filter kov-pro-btn--block"
                  >
                    <i className="icon-kov-pro-filter" />
                    Filter
                    {numFiltersApplied > 0 && (
                      <span className="filter-counter">{numFiltersApplied}</span>
                    )}
                  </button>
                </div>
              )}

              <DatabaseResults
                dispatch={dispatch}
                onUpdateReferences={updateSelectedReferences}
                onUpdateZillReferences={updateSelectedReferences}
                searchCount={searchCount}
                results={searchResults}
                filters={filters}
                isGlobalDatabase={database.isGlobalDatabase}
                zillCurricula={zillCurricula || []}
                references={filters.requirements.flatMap((ref) => ref.values)}
                loading={isLoading}
                orderTypes={orderTypes}
                onLoadMore={loadMoreSearchResults}
                dbKey={database.key}
              />
            </div>
          </div>
        </div>
      </section>
      {isTablet && !hideFilters && (
        <SidebarPortal open={filtersSidebarOpen} onClose={() => setFiltersSidebarOpen(false)}>
          <DatabaseFilters
            database={database}
            filters={tempFilters}
            theme={theme}
            zillCurricula={zillCurricula}
            hideFilters={hideFilters}
            dispatch={dispatch}
            update={updateSelectedReferences}
            onClearFilters={updateUrlSearchParams}
            doSearch={() => {
              dispatch(databaseActions.applyTempFilter());
              setFiltersSidebarOpen(false);
            }}
          />
        </SidebarPortal>
      )}
    </>
  ) : null;
};

Database.propTypes = {
  theme: PropTypes.object,
  database: PropTypes.object,
};

export default Database;
