import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTrainings } from '@src/reduxStore/trainings/trainingsData';
import TrainingThemeSpotlight from '@UI/trainings/trainingThemeSpotlight/trainingThemeSpotlight';
import { buildTrainingsAPIParams } from '@src/helpers/trainingsHelper';
import Banner from '@src/reactComponents/sections/banner/banner';
import { downloadTrainingsExcel } from '@src/reactComponents/trainings/exportTrainings';
import * as settings from '@src/config/settings';
import { isPersonAllowedToExportTrainings } from '@src/reduxStore/user/userDataAccess';
import { getIsUserKOV, getUserKey } from '@src/reduxStore/user/userSelectors';
import useTrainings from '@src/hooks/useTrainings';
import { cleanUpObjectEmptyValues } from '@src/helpers/utils';
import GlobalTrainingResults from './globalTrainingResults/globalTrainingResults';
import GlobalTrainingSearch from './globalTrainingSearch/globalTrainingSearch';
import GlobalTrainingFilters from './globalTrainingFilters/globalTrainingFilters';
import Notification from '../../UI/notification/notification';

require('./globalTrainings.scss');

const GlobalTrainings = () => {
  const themeFiltersRef = useRef();
  const userKey = useSelector(getUserKey);
  const isKOVuser = useSelector(getIsUserKOV);

  const [isFiltersBoxCollapsed, setIsFiltersBoxCollapsed] = useState(false);
  const [allowedExportTrainings, setAllowedExportTrainings] = useState(false);
  const [wasOutsideViewOptionsAlertManuallyClosed, setWasOutsideViewOptionsAlertManuallyClosed] =
    useState(false); // this is redundant and not good so it should be removed soon but I need confirmation from Bianca on a question I mde
  const isNewTrainingsSearchSession = window.location.search.length === 0;

  const collapseFiltersBoxHandler = useCallback(
    (newValue) => setIsFiltersBoxCollapsed(newValue),
    []
  );

  const {
    exportTrainingsFilters,
    updateFilter,
    dispatchSearch,
    dispatchLoadMore,
    dispatchOrderUpdate,
    viewModel,
    trainingsFiltersCnt,
    resetFiltersToViewOptions,
    isOutsideViewOptions,
    updateCheckboxFilter,
  } = useTrainings();

  const doSearch = (overwrittenTrainingsFilters = {}) => {
    dispatchSearch(overwrittenTrainingsFilters);
    collapseFiltersBoxHandler(true);
    setWasOutsideViewOptionsAlertManuallyClosed(false);
  };

  /**
   * This useEffect is used to trigger a search when the user lands in the trainings page if, in the url, he already have some filters applied
   * The filters to that query will be added in the useTrainings hook
   */
  useEffect(() => {
    if (!isNewTrainingsSearchSession) doSearch();
  }, []);

  useEffect(() => {
    if (userKey && isKOVuser)
      isPersonAllowedToExportTrainings(`/persons/${userKey}`).then((isAllowed) =>
        setAllowedExportTrainings(isAllowed)
      );
  }, [isKOVuser, userKey]);

  const cleanAllFiltersHandler = () => {
    resetFiltersToViewOptions();
    collapseFiltersBoxHandler(false);
  };

  const exportTrainings = async () => {
    const searchFilters = {
      ...exportTrainingsFilters,
      offset: undefined,
    };

    const { trainingsList } = await getTrainings(searchFilters, null, true);
    downloadTrainingsExcel(
      trainingsList,
      `${settings.apisAndUrls.searchApi}/search?${new URLSearchParams(
        await buildTrainingsAPIParams(cleanUpObjectEmptyValues(searchFilters))
      ).toString()}`
    );
  };

  return (
    <>
      <Banner
        menuItem={{ websiteType: 'trainings', title: 'Professionalisering' }}
        exportTrainingsFeature={{
          isAllowed: allowedExportTrainings,
          isEnabled: !isNewTrainingsSearchSession,
          exportTrainingsHandler: exportTrainings,
        }}
      />
      <GlobalTrainingSearch
        onSearch={() => doSearch()}
        collapseFiltersBoxHandler={() => collapseFiltersBoxHandler(!isFiltersBoxCollapsed)}
        isFiltersBoxCollapsed={isFiltersBoxCollapsed}
        viewModel={viewModel}
        trainingsFiltersCnt={trainingsFiltersCnt}
        updateSearchTerm={(term) => updateFilter('q', term)}
      />
      <GlobalTrainingFilters
        onSearch={() => doSearch()}
        cleanAllFilters={cleanAllFiltersHandler}
        isFiltersBoxCollapsed={isFiltersBoxCollapsed}
        viewModel={viewModel}
        themeFiltersRef={themeFiltersRef}
        updateFilter={updateFilter}
        updateCheckboxFilter={updateCheckboxFilter}
      />
      {!isNewTrainingsSearchSession && (
        <GlobalTrainingResults
          onSetOrder={(filterKey, filterValue) => dispatchOrderUpdate(filterValue)}
          onLoadMore={dispatchLoadMore}
          viewModel={viewModel}
          collapseFiltersBoxHandler={() => collapseFiltersBoxHandler(!isFiltersBoxCollapsed)}
          themeFiltersRef={themeFiltersRef}
        />
      )}
      <TrainingThemeSpotlight onSearch={doSearch} viewModel={viewModel} />
      <Notification
        isShown={isOutsideViewOptions && !wasOutsideViewOptionsAlertManuallyClosed}
        onDismiss={() => {
          setWasOutsideViewOptionsAlertManuallyClosed(true);
        }}
        type="warning"
        dismissLabel="OK"
      >
        Deze professinaliseringen vallen buiten jouw ingestelde kijkfilter.
      </Notification>
    </>
  );
};

export default GlobalTrainings;
