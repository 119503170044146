class ws500 {
  constructor($scope) {
    'ngInject';
    this.$scope = $scope;
  }

  $onInit() {
    this.$scope.$emit('dynamicMetaDataUpdated', {
      title: '500',
      description: null,
    });
  }
}

module.exports = {
  template: require('./ws500.html'),
  controllerAs: 'ctrl',
  controller: ws500,
  bindings: {},
};
