import React from 'react';
import PropTypes from 'prop-types';
import Banner from '@src/reactComponents/sections/banner/banner';
import ThemeDetailMenu from '@src/reactComponents/theme/themeDetailMenu/themeDetailMenu';
import LoginBlock from './LoginBlock';

const NotLoggedInPage = ({ theme, current }) => (
  <>
    <Banner menuItem={{ websiteType: 'forbidden' }} theme={theme} />
    <section id="shielded-content">
      {theme?.securityStatus === 'permitted' && (
        <div className="container">
          <div className="row">
            <div className={'kov-theme-title col-12'}>
              <ThemeDetailMenu
                sItems={theme.children}
                sColor={theme.root.color}
                sCurrent={current}
              />
            </div>
          </div>
        </div>
      )}
      {theme?.securityStatus === 'permitted' && <div className="divider-line" />}
      <div className="container">
        <div className="shielded-content">
          <div className="row">
            <div className="col-12">
              <h1 className="desktop-menu__service-title">
                <i className="icon-kov-pro-profile" /> AFGESCHERMDE INHOUD
              </h1>
            </div>

            <div className="col-12">
              <div className="shielded-content__notification">
                Je hebt geen toegang tot deze pagina of bijlage. Wat kun je doen?
              </div>
            </div>

            <LoginBlock />
          </div>
        </div>
      </div>
    </section>
  </>
);

NotLoggedInPage.propTypes = {
  theme: PropTypes.object,
  current: PropTypes.string,
};

export default NotLoggedInPage;
