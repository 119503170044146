import React, { useCallback, useEffect, useState } from 'react';
import ModalProvider from '@UI/modal/modalProvider';
import Modal from '@UI/modal/modal';
import useModal from '@hooks/useModal';
import { getCachedContents } from '@src/reduxStore/content/contentDataAccess';

const ThemeModal = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const { open: openModal, ...personModalProps } = useModal();

  const retrieveData = useCallback(
    async (key) => {
      const rawContent = await getCachedContents([`/content/${key}`]).then((resp) => resp[0]);
      setTitle(rawContent.title);
      setContent(rawContent.description);
      console.log(rawContent);
      openModal();
    },
    [openModal]
  );

  useEffect(() => {
    window.addEventListener('openTermModal', (event) => {
      retrieveData(event.detail);
      console.log(event.detail);
    });
    return () => {
      window.removeEventListener('openTermModal', () => {});
    };
  }, []);
  return (
    <ModalProvider {...personModalProps}>
      <Modal className="modal modal-term-center">
        <Modal.Header>
          <i className="icon-kov-pro-begrip"></i>
          <span>
            <h2 style={{ display: 'inline' }}>{`Begrip: ${title}`}</h2>
          </span>
        </Modal.Header>
        <Modal.Body html={content}></Modal.Body>
      </Modal>
    </ModalProvider>
  );
};

export default ThemeModal;
